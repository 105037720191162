import React from 'react'



const Landingpage = () => {
  return (
    <div style={styles.body}>
      <div style={styles.logo}>
        <img
          src="https://luuverr-app.s3.eu-west-1.amazonaws.com/assets/logo.png"
          alt="Logo"
        />
      </div>
      <h1 style={styles.heading}>Coming Soon</h1>
    </div>
  );
};

const styles = {
  body: {
    margin: 0,
    padding: 0,
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `url("https://images.unsplash.com/photo-1594684199092-e84f8cd802c2?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D") no-repeat center center/cover`,
    fontFamily: "Arial, sans-serif",
    color: "white",
    position: "relative",
    backdropFilter: "brightness(0.8)",
  },
  logo: {
    position: "absolute",
    top: "20px",
    left: "50%",
    transform: "translateX(-50%)",
  },
  heading: {
    fontSize: "4.5rem",
    padding: "20px 40px",
    borderRadius: "10px",
    textAlign: "center",
  },
};

export default Landingpage;
