import React, { useEffect, useState } from 'react';

import '../assets/css/profile.css';


import bckimg from "../assets/images/Rectangle 4223.png"
import { useParams } from 'react-router-dom';
import { BASE_URLUSER } from '../Configration';
const Profilepage = () => {
const [userinfo,setUserinfo]=useState();
    const id =useParams();
    console.log(id);
    // const token = JSON.parse(localStorage.getItem('LUR_login'));
    useEffect(()=>{
        const userdata= new FormData();
        userdata.append('user_id', id.id);
        userdata.append('ln','en');
        fetch(`${BASE_URLUSER}`, {
            method: 'POST',
            // headers: {
            //     Authorization: `Bearer ${token.token}`
            // },
            body: userdata
        })
            .then((response) => response.json())
            .then((data) => {
                setUserinfo(data.data);
            })
    },[id])
    return (
        <div className="Container " style={{ backgroundColor: 'black' ,height:"100vh"}}>
            <div className="d-flex justify-content-center align-items-center">
                <div style={{ position: 'relative' }} className="d-flex justify-content-center align-items-center">
                    <img src={userinfo?.user_profile} alt="user pic" style={{objectFit:"fill"}} className='userpic'/>
                    <div style={{position:"absolute"}}>
                    <img src={bckimg} alt='piccaption' style={{zIndex:"1px"}}className='userpic'/></div>
                    <div
                        style={{
                            position: 'absolute',
                            backgroundColor: '#00000073',
                            width: '91%',
                            // width: '343px',
                            color: '#FFFFFF',
                            bottom: '162px',
                            padding: '10px',
                            borderRadius: '15px'
                        }}
                    >
                        <p
                            style={{
                                marginBottom: '3px',
                                lineHeight: '31px',
                                color: '#FFFFFF',
                                fontFamily: 'Inter',
                                fontSize: '23px',
                                fontWeight: '700',
                                lineHeight: '31.47px'
                            }}
                        >
                           {userinfo?.full_name}, {userinfo?.age}
                        </p>
                        <p style={{ fontSize: '12px', fontWeight: '300', lineHeight: '12px', marginBottom: '3px' }}>
                            {' '}
                            <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5 0C2.57942 0 0.617188 1.96345 0.617188 4.38518C0.617188 7.05127 2.44395 7.67951 3.40238 9.31411C4.29465 10.8352 4.29538 12 5 12C5.73071 12 5.68459 10.9149 6.59762 9.31413C7.51039 7.71307 9.38281 7.05127 9.38281 4.38518C9.38281 1.96345 7.42058 0 5 0ZM5 6.06567C4.09447 6.06567 3.36036 5.33119 3.36036 4.42528C3.36036 3.97207 3.54387 3.5621 3.84066 3.26512C4.13743 2.96838 4.54723 2.78463 5 2.78463C5.90553 2.78463 6.63938 3.51912 6.63938 4.42528C6.63938 5.33119 5.90553 6.06567 5 6.06567Z"
                                    fill="#4CAF50"
                                />
                                <path
                                    d="M4.99992 0C4.87793 0 4.75718 0.00522656 4.6377 0.015C6.88877 0.199312 8.65828 2.08549 8.65828 4.38518C8.65828 7.05127 6.78586 7.71307 5.87309 9.31411C5.14995 10.582 5.02842 11.5262 4.64611 11.8642C4.74413 11.9525 4.85768 12 4.99992 12C5.73063 12 5.68451 10.9149 6.59754 9.31413C7.51031 7.71307 9.38273 7.05127 9.38273 4.38518C9.38273 1.96345 7.4205 0 4.99992 0Z"
                                    fill="#43A047"
                                />
                                <path
                                    d="M3.84059 3.26535L0.959047 6.14847C0.748273 5.66816 0.617188 5.09999 0.617188 4.38538C0.617188 3.18452 1.09967 2.09657 1.88103 1.30469L3.84059 3.26535Z"
                                    fill="#F44336"
                                />
                                <path
                                    d="M6.17098 5.5735C6.4609 5.27761 6.63968 4.8723 6.63968 4.42523C6.63968 3.51907 5.90562 2.78461 5.00023 2.78461C4.54739 2.78461 4.13754 2.96829 3.84082 3.26517L6.74355 0.36084C7.78113 0.811191 8.61009 1.65152 9.04634 2.697L6.17107 5.57383L6.17098 5.5735Z"
                                    fill="#42A5F5"
                                />
                                <path
                                    d="M6.74371 0.36084L6.49902 0.605668C7.4725 1.18007 8.20473 2.12004 8.50684 3.23695L9.04652 2.697C8.61025 1.6515 7.78129 0.811191 6.74371 0.36084Z"
                                    fill="#2196F3"
                                />
                                <path
                                    d="M6.17078 5.57378L3.00611 8.74021C2.31803 7.87295 1.43521 7.23538 0.958984 6.14825L3.84053 3.26514C3.54381 3.56202 3.36023 3.97211 3.36023 4.4252C3.36023 5.33108 4.09429 6.06554 4.99994 6.06554C5.45852 6.06554 5.87313 5.87713 6.17069 5.57345L6.17078 5.57378Z"
                                    fill="#FFC107"
                                />
                                <path
                                    d="M6.74318 0.360844L3.84042 3.2652L1.88086 1.30455C2.67558 0.499078 3.77951 0 4.99985 0C5.6194 0 6.20904 0.128719 6.74318 0.360844Z"
                                    fill="#1E88E5"
                                />
                                <path
                                    d="M4.99995 0C4.87798 0 4.75723 0.00520312 4.63777 0.015L4.6377 0.0150234C5.29471 0.0685123 5.93101 0.270471 6.49861 0.605672L6.7433 0.360844C6.20913 0.128719 5.61949 0 4.99995 0Z"
                                    fill="#1976D2"
                                />
                            </svg>{' '}
                           {userinfo?.address}
                        </p>
                    </div>

                    <div
                        style={{
                            position: 'absolute',
                            background: ' rgba(255, 255, 255, 0.1)',

                            backdropFilter: 'blur(5px)',
                            width: '91%',
                            // width: '343px',
                            color: '#FFFFFF',
                            bottom: '89px',
                            padding: '15px',
                            borderRadius: '15px'
                        }}
                    >
                        <div className="d-flex  ">
                            <div className="col-3 " style={{ borderRight: '1px  solid rgba(80, 81, 82, 1)', textAlign: 'center' }}>
                                <p
                                    style={{
                                        marginBottom: '3px',

                                        color: '#FFFFFF',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        lineHeight: '15px'
                                    }}
                                >
                                    {userinfo?.like_counts}
                                </p>
                                <p style={{ fontSize: '9px', fontWeight: '400', lineHeight: '9px', marginBottom: '3px' }}>Likes</p>
                                <div></div>
                            </div>
                            <div className="col-3 " style={{ borderRight: '1px  solid rgba(80, 81, 82, 1)', textAlign: 'center' }}>
                                <p
                                    style={{
                                        marginBottom: '3px',

                                        color: '#FFFFFF',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        lineHeight: '15px'
                                    }}
                                >
                                  {userinfo?.match_counts}
                                </p>
                                <p style={{ fontSize: '9px', fontWeight: '400', lineHeight: '9px', marginBottom: '3px' }}>Matches</p>
                            </div>
                            <div className="col-3 " style={{ borderRight: '1px  solid rgba(80, 81, 82, 1)', textAlign: 'center' }}>
                                <p
                                    style={{
                                        marginBottom: '3px',

                                        color: '#FFFFFF',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        lineHeight: '15px'
                                    }}
                                >
                                    {userinfo?.followers_counts}
                                </p>
                                <p style={{ fontSize: '9px', fontWeight: '400', lineHeight: '9px', marginBottom: '3px' }}>Followers</p>
                            </div>
                            <div className="col-3 me-2" style={{ textAlign: 'center' }}>
                                <p
                                    style={{
                                        marginBottom: '3px',

                                        color: '#FFFFFF',
                                        fontFamily: 'Inter',
                                        fontSize: '15px',
                                        fontWeight: '600',
                                        lineHeight: '15px'
                                    }}
                                >
                                    {userinfo?.profile_comment_counts}
                                </p>
                                <p style={{ fontSize: '9px', fontWeight: '400', lineHeight: '9px', marginBottom: '3px' }}>Comments</p>
                            </div>
                        </div>
                        <div></div>
                    </div>
                    <div
                        style={{
                            position: 'absolute',
                            background: ' rgba(246, 246, 246, 1)',

                            bottom: '0px',
                            width: '100%',
                            // width: '375px',

                            padding: '14px'
                        }}
                    >
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <p
                                    style={{
                                        marginBottom: '3px',

                                        color: '#33262A',
                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '20px'
                                    }}
                                >
                                    Are you interested?
                                </p>
                                <p
                                    style={{
                                        marginBottom: '3px',

                                        color: ' #33262A',

                                        fontFamily: 'Inter',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        lineHeight: '20px'
                                    }}
                                >
                                    Download <span style={{ fontWeight: '700' }}> Luuverr App</span>
                                </p>
                            </div>

                            <div>
                                <button style={{ backgroundColor: '#DD0150', borderRadius: '23.25px', color: '#FFFFFF', border: 'none' }}>
                                    Install Now
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Profilepage;
