
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import './assets/css/profile.css'
;import Profilepage from "./webpage/Profilepage";
import Landingpage from './Landingpage';
function App() {
  return (
    <div className="App">
    <BrowserRouter>
    <Routes>
      <Route path={'/:id'} element={<Profilepage/>}></Route>
   <Route path={'/'} element={<Landingpage/>}></Route>
    </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
